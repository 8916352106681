
.App {
  text-align: center;
  height: 100%;
}

.sync {
  display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;

    .sync-button {
      margin:8px;
      font-size: 2em;
      border: 2px solid white;
      background-color: #606060;
      color:white;

      &:hover {
        background-color: #808080;
        cursor: pointer;
      }
      &:active {
        background-color: #404040;
      }
    }
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  * {
    margin: 10px;
  }
}

.token-list {
  width:100%;
  display: grid;
  grid-template-columns: repeat(4, calc(25% - 15px));
  gap: 15px;
  justify-content: center;
}

.token {
  display: flex;
  flex-direction: column;
}

.selected-img {
  border: 5px solid #32b7ff;
}

.unselected-img {
  opacity: 0.5;
}
.selected-text {
  font-weight: bold;
}

html, body, #root {
  height: 100%;
  font-family: 'Helvetica', sans-serif;
  background-color: #2b2d36;
  color: white;
}

.success {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .password {
    font-size: 3em;
  }

  .clicked {
    background-color: #344865;
    color: white;
  }
}
.unsync-button, .confirm-button {
  // border: 1px solid white;
  border: none;
  padding:10px;
  background-color: #606060;
  color:white;

  &:hover {
    background-color: #808080;
    cursor: pointer;
  }
}

.confirm-button {
  background-color: #3f5a7f;
  &:hover {
    background-color: #6b8fbf;
  }
}
.copy {
  font-size: 1em;
    // border: 1px solid white;
  border: none;
  padding:10px;
  margin-bottom:5px;
  background-color: #3f5a7f;
  color:white;

  &:hover {
    background-color: #6b8fbf;
    cursor: pointer;
  }
  &.clicked {
    background-color: #63be68;
  }
}


// button {
//   background-color: white;
//   border: 1px solid black;
//   border-radius: 5px;
// }